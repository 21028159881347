.container > div {
    cursor: pointer;
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 3em;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
    -webkit-user-select: none;
    user-select: none;
    border-radius:16px;
  }