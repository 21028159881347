/* @tailwind base; */
/**@tailwind base;**/
@tailwind components;
@tailwind utilities;

/**background-color: #100101;**/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
body{
  background-color: #e3e3c7;
  /*background-image: linear-gradient(to right, black, gold);*/
  
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height:100%;
}

html, body {
  height: 100%;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}
.App {
  text-align: center;
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.navbar {
  width: 100%;
  height: 100px;
  background-color: #181818;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.logoimage {
  width:105px;
  height: 100px;
  margin-left: 25px;
}

/**.logoimage {
  display: flex;
  align-items: center;
}**/

/**justify-content: flex-end;**/
.navbar .links {
  text-align: center;
  margin-right: 50px;
}

.links a {
  color:white;
  text-decoration: none;
  margin: 10px;
}


.navbar .user {
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.user p {
  font-size:15px;
  margin-right: 10px;
  color:white;
}
.user img {
  font-size: 15px;
  border-radius: 50%
}

.userlogo{
  margin-right:10px;
}

.submitForm:hover{
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.submitForm{
  background-color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  color: black;
  border: 2px solid red;
  padding: 10px 10px;
}

.aceLogo{
  justify-content: flex-start;
  display:flex;
}

.container {
  display: inline-block;
  margin-left: 50px;
}

/**.animation-wrapper {
  all: initial;
  * {
    all: unset;
  }
}

.animation-wrapper .typed-out {
  overflow: hidden;
  box-sizing: content-box;
  border-right: .15em solid red;
  white-space: nowrap;
  font-size: 3rem;
  width: 0;
  animation: 
    typing 15s steps(20, end) infinite,
    blink-caret .5s step-end infinite;
}

@keyframes typing {
  0%, 33.33%, 66.66% { width: 0 }
  6.66%, 40%, 73.33% { width: 100% }
  26.66%, 60%, 93.33% { width: 100% }
  33.33%, 66.66%, 99.99% { width: 0 }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: red }
}

.typed-out::before {
  content: "ACE - for the students";
  animation: cycleText 15s steps(1, end) infinite;
}

@keyframes cycleText {
  0%, 33.32% { content: "ACE - for the students"; }
  33.33%, 66.65% { content: "ACE - for the teachers"; }
  66.66%, 99.99% { content: "ACE - for success"; }
}**/

ul{
    list-style-type: none;
    text-align: center;
  
}

.col{
  display:flex;
  flex-direction: column;
  justify-content: center
  
}
.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.horizontal{
  width: 20%;
  border-color: red;
}

.image-grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

.image-grid-row {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-bottom: 2rem;
  text-align: center;
}

.team-member img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1rem;
}

.team-member h1 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.2;
}

.team-member h2 {
  margin: 0.2rem 0 0.5rem;
  font-size: 1.2rem;
  font-weight: normal;
  color: #adadad;
}

.team-member p {
  margin-top: 0.5rem;
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .image-grid-row {
      flex-direction: column;
      align-items: center;
  }
}



.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.text-container {
  /**color: transparent;**/
  background-color: transparent;
  color: white;
  /**background-image: linear-gradient(45deg, red, gold);**/
  padding: 20px;
  border-radius: 8px;
}

.text-container h3 {
  margin-top: 0;
}

.text-container.centered {
  max-width: 600px;
  margin: 0 auto;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .content-grid {
      grid-template-columns: 1fr;
  }
}

.horizontal1{
  width: 15%;
}

.horizontal2{
  width: 15%;
}

.homeimage{
  width:400px;
  height: 400px;
}

.hrfooter{
  width:50%;
}

.title h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 1.2;
}

.title p {
  margin: 0.2rem 0 0.5rem;
  font-size: 1.2rem;
  font-weight: normal;
  color: #666;
}

.underlined{
  text-decoration: underline;
  color: white;
}

.userdisplayname{
  color:black;
}

.gradient-text {
  background-image: linear-gradient(to right, red, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.aboutpara{
  margin-left:400px;
  margin-right:400px;
}

