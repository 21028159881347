@tailwind components;
@tailwind utilities;

.logo{
    height:250px;
    margin-top:35px;
}

.ok {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 125px;
    width: 100%;
    padding: 0 2rem;
    /*background-color: rgb(220, 151, 151);*/
    /*background-image: linear-gradient(to right, #e3e3c7, gold);*/
    /*background-color: #e3e3c7;*/
    background-color: lightblue;
    margin-top: 10px;
  }
  .logo-container {
    flex: 0 0 auto;
  }
  .links-container {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .nav-link {
    color: black;
    text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
  }
   .nav-link:hover {
    color: #ef4444; 
}
  @media (max-width: 768px) {
    .links-container {
      display: none;
    }
  }

  hr{
    width:90%;
  }


